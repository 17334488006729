import React, { useEffect, useState } from 'react';
import Card from '../Card';
import FunFact from '../FunFact';
import Hero from '../Hero';
import ShowcasePortfolioHome from './ShowcasePortfolioHome';
import Div from '../Div';
import SectionHeading from '../SectionHeading';
import Spacing from '../Spacing';
import Cta from '../Cta';
import LogoList from '../LogoList';
import MovingText from '../MovingText';
import PortfolioSlider from '../Slider/PortfolioSlider';
import PortfolioVideoSlider from '../Slider/PortfolioVideoSlider';
import PostSlider from '../Slider/PostSlider';
import TestimonialSlider from '../Slider/TestimonialSlider';
import DirectorSlider from '../Slider/DirectorSlider';
import TeamSlider from '../Slider/TeamSlider';
import VideoModal from '../VideoModal';
import TimelineSlider from '../Slider/TimelineSlider';
import { pageTitle } from '../../helper';
import axiosInstance from '../../helper/axiosInterceptor';

export default function Home() {
  pageTitle('Home');
  const [directorsData, setDirectorsData] = useState([])

  useEffect(() => {
    axiosInstance('https://backend.weetwist.com/api/director', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      const data = response.data?.map(item => ({
        testimonialThumb: item.photo,
        testimonialText: item.about,
        avatarName: item.name,
        avatarDesignation: item.designation
      }))
      console.log(data)
      setDirectorsData(data)
    })
  }, [])
  // Hero Social Links
  const heroSocialLinks = [
    {
      name: 'Facebook',
      links: 'https://www.facebook.com/WeeTwistInc',
    },
    {
      name: 'Youtube',
      links: 'https://www.youtube.com/@weetwist',
    },
  ];

  // FunFact Data
  // const funfaceData = [
  //   {
  //     title: 'Global Happy Clients',
  //     factNumber: '40K',
  //   },
  //   {
  //     title: 'Project Completed',
  //     factNumber: '50K',
  //   },
  //   {
  //     title: 'Team Members',
  //     factNumber: '245',
  //   },
  //   {
  //     title: 'Digital products',
  //     factNumber: '550',
  //   },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {/* Start Hero Section */}
      <ShowcasePortfolioHome />
      {/* <Hero
        title="Creativity In <br/>Our Blood Line"
        subtitle="We deliver best problem solving solution for our client and provide finest finishing product in present and upcoming future."
        btnText="Get a Quote"
        btnLink="/contact"
        scrollDownId="#service"
        socialLinksHeading="Follow Us"
        heroSocialLinks={heroSocialLinks}
        bgImageUrl="/images/hero_bg.jpeg"
      /> */}
      {/* End Hero Section */}

      {/* Start FunFact Section */}
      {/* <div className="container">
        <FunFact
          variant="cs-type1"
          title="Our fun fact"
          subtitle="Sed ut perspiciatis unde omnis iste natus error voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis."
          data={funfaceData}
        />
      </div> */}
      {/* End FunFact Section */}
      {/* Start About Section */}
      <Spacing lg="150" md="80" />
      <Div className="container">
        <Div className="row">
          <Div className="col-xl-5 col-lg-7">
            <SectionHeading
              title="A Dhaka Based Production House"
              subtitle="About Us"
              styles={{ color : "#ee364e"}}
            >
              <Spacing lg="30" md="20" />
              <p className="cs-m0">
              Wee Twist started its journey officially at the beginning of 2022. At Wee Twist, we're dedicated to crafting compelling audio-visuals and films to help you achieve your business goals. We're a bunch of industry professionals containing vast experience of working for top brands of all kind of categories like Telco, Mobile Phones, Electronics, F&B, Beauty Products, NGO etc. Our collaborators' expertise excelled on shooting for full length feature films to short films to 30 seconder commercials, comedy to tear jerker contents. Our team members has shot in every environment, from clean rooms to operating rooms to bedrooms. From busy traffic to mountains of Bandarban to deep sea of Bay of Bengal
              </p>
              <Spacing lg="30" md="30" />
              <Div className="cs-separator cs-accent_bg"></Div>
              <Spacing lg="25" md="40" />
            </SectionHeading>
          </Div>
          <Div className="col-lg-7">
            <img
              src="/images/about-us.png"
              alt="About"
              className="w-100 cs-radius_15"
              style={{ height: "85%" }}
            />
            <Spacing lg="25" md="25" />
          </Div>
        </Div>
      </Div>
      <Spacing lg="75" md="55" />
      {/* End About Section */}
      {/* Start DirectorSlider Section */}
      <DirectorSlider directorsData={directorsData} />
      {/* End DirectorSlider Section */}

      {/* Start Service Section */}
      <Spacing lg="150" md="80" />
      <Div id="service">
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Projects we can help you with"
                subtitle="What We Do"
                btnText="See All"
                btnLink="/what-we-do"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="ADVERTISEMENT"
                    link="/service/advertisement"
                    src="/images/what-we-do/advertisement.png"
                    alt="Service"
                    ServiceDetails=
                      "At our studio, we go beyond traditional advertising approaches and transform your brand message into captivating audio-visual experiences. Our team excels in creating advertising films that not only promote your product or service but also evoke emotions and engage your audience. By seamlessly blending music, storytelling, and stunning visuals, we craft advertisements that leave a lasting impression and drive impactful results."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="FEATURE FILMS"
                    link="/service/feature-films"
                    src="/images/what-we-do/feature-film.png"
                    alt="Service"
                    ServiceDetails="When it comes to feature films, we understand the power of visuals in conveying narratives and evoking deep emotions. Our expertise lies in translating the essence of your story into a visual masterpiece that complements the story and enhances the overall cinematic experience. Whether it's a romantic drama, a thrilling action flick, or a thought-provoking indie film, we have the creative prowess to bring your vision to life through our film production."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="WEB SERIES"
                    link="/service/web-series"
                    src="/images/what-we-do/web-series.png"
                    alt="Service"
                    ServiceDetails="In the era of digital content, web series have become immensely popular, capturing the attention of viewers worldwide. We recognize the significance of compelling visuals and well-crafted storytelling in the success of a web series. With our expertise in Web Series production, we create captivating episodes that seamlessly integrate music, visuals, and narratives. Our team ensures that each episode not only leaves the audience eagerly anticipating the next installment but also enhances the overall entertainment value of the web series."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="MUSIC VIDEOS"
                    link="/service/music-videos"
                    src="/images/what-we-do/music-videos.png"
                    alt="Service"
                    ServiceDetails="We don’t just make music videos, we create musical films by crafting an unique concept and visuals for your beloved musical project. We make sure our visuals add a layer of rhythm to your music."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="INTERNATIONAL COLLABORATIONS"
                    link="/service/international-collaborations"
                    src="/images/what-we-do/global-collaborations.png"
                    alt="Service"
                    ServiceDetails=
                      "@Wee Twist, We try creating magic locally and we are capable of create that magic globally as well. We are committed to bring true artisan into the world of storytelling. We are not just a production company, but a creative hub with a constant itch to accept challenges if the idea is appealing. If you’re the one from overseas seeking beautiful, bright and vibrant Bengali landscapes with an eclectic mix of versatile cultures and people do not think twice before contacting us. We will ensure a smooth, hassle free production with all means. If you’re planning a Bangladesh-based production, our team will keep you as our top priority and assist you with everything. After all, we are a welcoming nation by culture and we treat our guests like gods."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='DOCUMENTARY'
                    link='/service/documentary'
                    src='/images/what-we-do/documentary.png'
                    alt='Service'
                    ServiceDetails="Documentaries have the power to shed light on important stories, provoke thought, and create awareness. We approach documentary films with a deep understanding of the subject matter, respecting the integrity of the narrative. Through our meticulous attention to detail, we capture the essence of your documentary and transform it into a visually immersive experience. By harmonizing visuals, music and sound design, we elevate the impact of your documentary, allowing audiences to connect with the subject matter on a deeper level."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      {/* End Service Section */}

      {/* Start Portfolio Section */}
      <Spacing lg="150" md="50" />
      <Div>
        <Div className="container">
          <SectionHeading
            title="Works to explore"
            subtitle="Our Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioSlider />
      </Div>
      <Spacing lg="150" md="50" />
      {/* <Div>
        <Div className="container">
          <SectionHeading
            title="Videos"
            subtitle="Our Projects"
            variant="cs-style1 text-center"
          />
          <Spacing lg="90" md="45" />
        </Div>
        <PortfolioVideoSlider />
      </Div> */}
      {/* End Portfolio Section */}

      {/* Start Awards Section */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* <Div className="cs-shape_wrap_2">
        <Div className="cs-shape_2">
          <Div />
        </Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="We get multiple awards"
                subtitle="Our Awards"
                variant="cs-style1"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <TimelineSlider />
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Awards Section */}

      {/* Start Video Block Section */}
      {/* <Spacing lg="130" md="70" />
      <Div className="container">
        <h2 className="cs-font_50 cs-m0 text-center cs-line_height_4">
          Our agile process is ability to adapt and respond to change. Agile
          organizations view change as an opportunity, not a threat.
        </h2>
        <Spacing lg="70" md="70" />
        <VideoModal
          videoSrc="https://www.youtube.com/watch?v=VcaAVWtP48A"
          bgUrl="/images/video_bg.jpeg"
        />
      </Div> */}
      {/* End Video Block Section */}

      {/* Start Team Section */}
      {/* <Spacing lg="145" md="80" />
      <Div className="container">
        <SectionHeading
          title="Awesome team <br/>members"
          subtitle="Our Team"
          variant="cs-style1"
        />
        <Spacing lg="85" md="45" />
        <TeamSlider />
      </Div> */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* End Team Section */}

      {/* Start Testimonial Section */}
      {/* <TestimonialSlider /> */}
      {/* End Testimonial Section */}

      {/* Start Blog Section */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* <Div className="cs-shape_wrap_4">
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title="Explore recent publication"
                subtitle="Our Blog"
                btnText="View More Blog"
                btnLink="/blog"
              />
              <Spacing lg="90" md="45" />
            </Div>
            <Div className="col-xl-7 offset-xl-1">
              <Div className="cs-half_of_full_width">
                <PostSlider />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div> */}
      {/* End Blog Section */}

      {/* Start MovingText Section */}
      {/* <Spacing lg="125" md="70" /> */}
      {/* <MovingText text="Our reputed world wide partners" /> */}
      {/* <Spacing lg="105" md="70" /> */}
      {/* End MovingText Section */}

      {/* Start LogoList Section */}
      {/* <Div className="container">
        <LogoList />
      </Div> */}
      {/* <Spacing lg="150" md="80" /> */}
      {/* End LogoList Section */}

      {/* Start CTA Section */}
      <Div className="container">
        <Cta
          title="Let’s discuss & make <br />something <i>cool </i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
      <Spacing lg="150" md="80" />
      {/* End CTA Section */}
      {/* Start Map Section */}
      <Div className="cs-google_map">
        <iframe
          src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3651.177906416681!2d90.40690778702421!3d23.776678332737916!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c75e6c1ece3f%3A0xdd3d43dd5dd74815!2sWee%20Twist!5e0!3m2!1sbn!2sbd!4v1687846838339!5m2!1sbn!2sbd"
          allowFullScreen
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
          title="Google Map"
        ></iframe>
      </Div>
      {/* End Map Section */}
    </>
  );
}

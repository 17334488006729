import React, { useState, useEffect } from "react";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import Spacing from "../Spacing";
import axiosInstance from "../../helper/axiosInterceptor";

export default function DirectorDetails() {
  pageTitle("Directors");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const [directorsData, setDirectorsData] = useState([]);

  useEffect(() => {
    axiosInstance("https://backend.weetwist.com/api/director", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      const data = response.data;
      setDirectorsData(data);
      console.log(data);
    });
  }, []);
  return (
    <>
      <PageHeading
        title="Directors"
        bgSrc="/images/about_hero_bg.jpeg"
        pageLinkText="Directors"
      />
      <Spacing lg="150" md="80" />
      {directorsData &&
        directorsData.map((director) => (
          <div key={director.id}>
            <Div className="container">
              <Div className="row align-items-center">
                <Div className="col-xl-5 col-lg-6">
                  <Div className="cs-radius_15 cs-shine_hover_1">
                    <img src={director.photo} alt="Member" className="w-100" />
                  </Div>
                </Div>
                <Div className="col-lg-6 offset-xl-1">
                  <Spacing lg="0" md="45" />
                  <Div className="cs-section_heading cs-style1">
                    <h2 className="cs-section_title">{director.name}</h2>
                    <Div className="cs-height_10 cs-height_lg_10" />
                    <h3 className="cs-section_subtitle" style={{
                      color: "#ee364e"
                    }}>
                      {director.designation}
                    </h3>
                    <Div className="cs-height_5 cs-height_lg_5" />
                    <Div className="cs-separator cs-accent_bg" />
                    <Div className="cs-height_45 cs-height_lg_25" />
                    <p className="cs-m0">{director.about}</p>

                    <Div className="cs-height_45 cs-height_lg_30" />
                    {/* <SocialWidget/> */}
                  </Div>
                </Div>
              </Div>
            </Div>
            <Spacing lg="150" md="80" />
          </div>
        ))}

      <Div className="container">
        <Cta
          title="Let’s discuss & make <br />something <i>cool</i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}

import React from 'react'
import { Icon } from '@iconify/react';
import './directors.scss'
import Div from '../Div';
import Button from "../Button";

export default function DirectorsDetails({testimonialText, avatarName, avatarDesignation, maxLength}) {
  let trimmedText = testimonialText;

  if (testimonialText.length > maxLength) {
    trimmedText = testimonialText.slice(0, maxLength) + '...';
  }

  return (
    <Div className="cs-testimonial cs-style1">
      <Div className="cs-testimonial_quote">
        <Icon icon="fa:quote-left" />               
      </Div>
      <Div className="cs-testimonial_text">{trimmedText}</Div>
      <Button btnLink={"/director-details"} btnText={"Read More"} />
      <h2 className="cs-testimonial_avatar_name">{avatarName}</h2>
      <Div className="cs-testimonial_avatar_designation text-uppercase" style={{
        color: "#ee364e"
      }}>{avatarDesignation}</Div>
    </Div>
  )
}

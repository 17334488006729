import axios from 'axios';

// Additional imports if needed

const axiosInstance = axios.create();

// Request interceptor
axiosInstance.interceptors.request.use(
  config => {
    // Modify the config object before sending the request
    // For example, you can add headers or perform authentication checks

    return config;
  },
  error => {
    // Handle request errors

    return Promise.reject(error);
  }
);

// Response interceptor
axiosInstance.interceptors.response.use(
  response => {
    // Handle successful responses

    return response;
  },
  error => {
    // Handle error responses

    return Promise.reject(error);
  }
);

export default axiosInstance;

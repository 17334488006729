import React, { useEffect, useState } from "react";
import axiosInstance from "../../helper/axiosInterceptor";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import Team from "../Team";

export default function TeamPage() {
  const [teamData, setTeamData] = useState([]);
  pageTitle("Team");

  useEffect(() => {
    axiosInstance("https://backend.weetwist.com/api/team", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((res) => {
      const data = res.data?.map((item) => ({
        memberName: item.name,
        memberImage: item.photo,
        memberDesignation: item.designation,
        memberDescription: item.about,
      }));
      console.log(data);
      setTeamData(data);
    });
  }, []);
  console.log(
    teamData.filter(
      (item) => item.memberDesignation?.toLowerCase() == "executive producer"
    ),
    teamData
  );

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Team"
        bgSrc="images/about_hero_bg.jpeg"
        pageLinkText="Team"
      />
      <Spacing lg="145" md="80" />
      {/* Executive Section Start */}
      {teamData
        .filter(
          (item) =>
            item.memberDesignation?.toLowerCase() == "executive producer"
        )
        .map(
          ({
            memberImage,
            memberName,
            memberDesignation,
            memberDescription,
          }) => (
            <>
              <Div className="container">
                <Div className="row align-items-center">
                  <Div className="col-xl-5 col-lg-6">
                    <Div className="cs-radius_15 cs-shine_hover_1">
                      <img src={memberImage} alt="Member" className="w-100" />
                    </Div>
                  </Div>
                  <Div className="col-lg-6 offset-xl-1">
                    <Spacing lg="0" md="45" />
                    <Div className="cs-section_heading cs-style1">
                      <h2 className="cs-section_title">{memberName}</h2>
                      <Div className="cs-height_10 cs-height_lg_10" />
                      <h3 className="cs-section_subtitle" style={{
                        color: "#ee364e"
                      }}>
                        {memberDesignation}
                      </h3>
                      <Div className="cs-height_5 cs-height_lg_5" />
                      <Div className="cs-separator cs-accent_bg" />
                      <Div className="cs-height_45 cs-height_lg_25" />
                      <p className="cs-m0">{memberDescription}</p>

                      <Div className="cs-height_45 cs-height_lg_30" />
                      {/* <SocialWidget/> */}
                    </Div>
                  </Div>
                </Div>
              </Div>
              <Spacing lg="150" md="80" />
            </>
          )
        )}
      {/* Executive Section End */}
      <Div className="container">
        <SectionHeading
          title="Meet our awesome <br/>team members"
          subtitle="Our Team"
          variant="cs-style1 text-center"
        />
        <Spacing lg="90" md="45" />

        <Div className="row">
          {teamData
            .filter(
              (item) =>
                item.memberDesignation?.toLowerCase() !== "executive producer"
            )
            .map((item, index) => (
              <Div key={index} className="col-lg-3 col-sm-6">
                <Team
                  memberImage={item.memberImage}
                  memberName={item.memberName}
                  memberDesignation={item.memberDesignation}
                  // memberSocial={item.memberSocial}
                />
                <Spacing lg="80" md="30" />
              </Div>
            ))}
        </Div>
        <Spacing lg="70" md="50" />
        <Div className="container">
          <Cta
            title="Let’s discuss make <br />something <i>cool</i> together"
            btnText="Apply For Meeting"
            btnLink="/contact"
            bgSrc="/images/cta_bg.jpeg"
          />
        </Div>
      </Div>
    </>
  );
}

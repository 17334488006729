import React, { useEffect, useState } from 'react'
import axiosInstance from '../../helper/axiosInterceptor';
import Portfolio from '../Portfolio'
import Div from '../Div'
import Slider from 'react-slick';

export default function PortfolioSlider() {
  const [portfolioData, setPortFolioData] = useState([]);

  useEffect(() => {
    axiosInstance('https://backend.weetwist.com//api/portfolio/photo', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
    .then(response => {
      const data = response.data[0]?.media_files.map(item => ({
        title: response.data[0]?.title,
        src: `https://backend.weetwist.com/${item.media_file}`
      }))
      setPortFolioData(data)
      console.log(portfolioData);
    })
  }, [])
  
  /** Slider Settings **/
  const settings = {
    className: "center",
    centerMode: true,
    infinite: true,
    centerPadding: "0",
    slidesToShow: portfolioData.length < 4 ? portfolioData.length - 1 : 3,
    speed: 500,
    dots: true,
    arrows:false,
    autoplay: true,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1
        }
      }
    ]
  };
  
  return (
    <Slider {...settings} className='cs-slider cs-style3 cs-gap-24'>
      {portfolioData.map((item, index)=> (
        <Div key={index}>
          <Portfolio 
            title={item.title} 
            subtitle={item.subtitle}
            href="/our-works"
            src={item.src}
          />
        </Div>
      ))}
    </Slider>
  )
}

import React from 'react'
import Div from '../Div'
import ContactInfoWidget from '../Widget/ContactInfoWidget'
import MenuWidget from '../Widget/MenuWidget'
import Newsletter from '../Widget/Newsletter'
import SocialWidget from '../Widget/SocialWidget'
import TextWidget from '../Widget/TextWidget'
import './footer.scss'
import DeveloperWidget from '../Widget/DeveloperWidget'

export default function Footer({copyrightText, logoSrc, logoAlt, text}) {
  const copyrightLinks = [
    {
      title: 'Devsstream',
      href: 'https://devsstream.com/'
    }
  ]
  
  const serviceMenu = [
    {
      title: 'Advertisement',
      serviceDetails: "At our studio, we go beyond traditional advertising approaches and transform your brand message into captivating audio-visual experiences. Our team excels in creating advertising films that not only promote your product or service but also evoke emotions and engage your audience. By seamlessly blending music, storytelling, and stunning visuals, we craft advertisements that leave a lasting impression and drive impactful results.",
      href: '/what-we-do'
    },
    {
      title: 'Feature Films',
      serviceDetails: "When it comes to feature films, we understand the power of visuals in conveying narratives and evoking deep emotions. Our expertise lies in translating the essence of your story into a visual masterpiece that complements the story and enhances the overall cinematic experience. Whether it's a romantic drama, a thrilling action flick, or a thought-provoking indie film, we have the creative prowess to bring your vision to life through our film production.",
      href: '/what-we-do'
    },
    {
      title: 'Web Series',
      serviceDetails: "In the era of digital content, web series have become immensely popular, capturing the attention of viewers worldwide. We recognize the significance of compelling visuals and well-crafted storytelling in the success of a web series. With our expertise in Web Series production, we create captivating episodes that seamlessly integrate music, visuals, and narratives. Our team ensures that each episode not only leaves the audience eagerly anticipating the next installment but also enhances the overall entertainment value of the web series.",
      href: '/what-we-do'
    },
    {
      title: 'Music Videos',
      serviceDetails: "We don’t just make music videos, we create musical films by crafting an unique concept and visuals for your beloved musical project. We make sure our visuals add a layer of rhythm to your music.",
      href: '/what-we-do'
    },
    {
      title: 'International Collaborations',
      serviceDetails: "@Wee Twist, We try creating magic locally and we are capable of create that magic globally as well. We are committed to bring true artisan into the world of storytelling. We are not just a production company, but a creative hub with a constant itch to accept challenges if the idea is appealing. If you’re the one from overseas seeking beautiful, bright and vibrant Bengali landscapes with an eclectic mix of versatile cultures and people do not think twice before contacting us. We will ensure a smooth, hassle free production with all means. If you’re planning a Bangladesh-based production, our team will keep you as our top priority and assist you with everything. After all, we are a welcoming nation by culture and we treat our guests like gods.",
      href: '/what-we-do'
    },
    {
      title: 'Documentary',
      serviceDetails: "Documentaries have the power to shed light on important stories, provoke thought, and create awareness. We approach documentary films with a deep understanding of the subject matter, respecting the integrity of the narrative. Through our meticulous attention to detail, we capture the essence of your documentary and transform it into a visually immersive experience. By harmonizing visuals, music and sound design, we elevate the impact of your documentary, allowing audiences to connect with the subject matter on a deeper level.",
      href: '/what-we-do'
    },
  ]

  return (
    <footer className="cs-fooer">
      <Div className="cs-fooer_main">
        <Div className="container">
          <Div className="row">
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <TextWidget 
                  logoSrc='/images/logos/logo-red.png' 
                  logoAlt='Logo'
                  text ="Welcome to Wee Twist. We are specialized in creating engaging and captivating content for various platforms. Our team of talented professionals is dedicated to deliver high-quality media solutions that resonate with your audience"
                />
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <MenuWidget menuItems={serviceMenu} menuHeading='What We Do'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <ContactInfoWidget title='Contact Us'/>
              </Div>
            </Div>
            <Div className="col-lg-3 col-sm-6">
              <Div className="cs-footer_item">
                <Newsletter 
                  title='Social Media' 
                  subtitle="Join us on this creative journey and let's bring your vision to life!"
                  placeholder='example@gmail.com'
                />
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Div className="container">
        <Div className="cs-bottom_footer">
          <Div className="cs-bottom_footer_left">
            <Div className="cs-copyright">Copyright © 2023 Wee Twist.</Div>
          </Div>
          <Div className="cs-bottom_footer_right">
            <DeveloperWidget menuItems={copyrightLinks} variant=' cs-style2'/>
          </Div>
        </Div>
      </Div>
    </footer>
  )
}

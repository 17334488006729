import React, { useEffect, useState } from 'react';
import axios from 'axios';
import axiosInstance from '../../helper/axiosInterceptor';
import { pageTitle } from '../../helper';
import 'swiper/css';
import 'swiper/css/pagination';
import Hero6 from '../Hero/Hero6';

export default function ShowcasePortfolioHome() {
  // pageTitle('Showcase Portfolio');
  const [showcaseData, setShowCaseData] = useState([]);
  const [mediaType, setMediaType] = useState("");
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  useEffect(() => {
    axiosInstance('https://backend.weetwist.com/api/home', {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    })
      .then(res => {
        console.log(res.data[0]?.media_files)
        let data;
        if (res.data[0]?.media_type == "photo") {
          data = res.data[0]?.media_files.map(item => ({
            title: res.data[0].title,
            imgUrl: `https://backend.weetwist.com/${item.media_file}`,
            href: '#',
          }));
        } else {
          data = {
            src: `https://backend.weetwist.com/${res.data[0].media_files[0].media_file}`
          }
        }
        console.log(data)
        setShowCaseData(data)
        setMediaType(res.data[0]?.media_type)
      })
  }, []);

  const heroSocialLinks = [
    {
      name: 'Facebook',
      links: 'https://www.facebook.com/WeeTwistInc',
    },
    {
      name: 'Youtube',
      links: 'https://www.youtube.com/@weetwist',
    },
  ];

  return (
    <Hero6
      heroSocialLinks={heroSocialLinks}
      socialLinksHeading="Follow Us"
      showcaseData={showcaseData}
      mediaType={mediaType}
    />
  );
}

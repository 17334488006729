import React, { useEffect } from 'react'
import { pageTitle } from '../../helper'
import Card from '../Card'
import Cta from '../Cta'
import PageHeading from '../PageHeading'
import PricingTableList from '../PricingTable/PricingTableList'
import Div from '../Div'
import SectionHeading from '../SectionHeading'
import TestimonialSlider from '../Slider/TestimonialSlider'
import Spacing from '../Spacing'
import Portfolio2 from '../Portfolio/Portfolio2'
import Portfolio3 from '../Portfolio/Portfolio3'

export default function ServicesPage() {
  pageTitle('What We Do');
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])

  const portfolioData = [
    {
      // title: '',
      subtitle: 'At our studio, we go beyond traditional advertising approaches and transform your brand message into captivating audio-visual experiences. Our team excels in creating advertising films that not only promote your product or service but also evoke emotions and engage your audience. By seamlessly blending music, storytelling, and stunning visuals, we craft advertisements that leave a lasting impression and drive impactful results.',
      // btnText: 'See Details',
      // btnLink: '/portfolio/portfolio-details',
      imageUrl: '/images/services/advertisement.png',
      category: 'Advertisement',
    },
    {
      // title: 'Euro corporate agency for healthy environment',
      subtitle: "When it comes to feature films, we understand the power of visuals in conveying narratives and evoking deep emotions. Our expertise lies in translating the essence of your story into a visual masterpiece that complements the story and enhances the overall cinematic experience. Whether it's a romantic drama, a thrilling action flick, or a thought-provoking indie film, we have the creative prowess to bring your vision to life through our film production.",
      // btnText: 'See Details',
      // btnLink: '/portfolio/portfolio-details',
      imageUrl: '/images/services/feature-film.png',
      category: 'Feature Film',
    },
    {
      // title: 'Powerful admin dashboard design',
      subtitle: "In the era of digital content, web series have become immensely popular, capturing the attention of viewers worldwide. We recognize the significance of compelling visuals and well-crafted storytelling in the success of a web series. With our expertise in Web Series production, we create captivating episodes that seamlessly integrate music, visuals, and narratives. Our team ensures that each episode not only leaves the audience eagerly anticipating the next installment but also enhances the overall entertainment value of the web series.",
      // btnText: 'See Details',
      // btnLink: '/portfolio/portfolio-details',
      imageUrl: '/images/services/web-series.png',
      category: 'Web Series',
    },
    {
      // title: 'Crypto financial trading apps for hedge fund',
      subtitle: "We don’t just make music videos, we create musical films by crafting an unique concept and visuals for your beloved musical project. We make sure our visuals add a layer of rhythm to your music.",
      // btnText: 'See Details',
      // btnLink: '/portfolio/portfolio-details',
      imageUrl: '/images/services/music-videos.png',
      category: 'Music Video',
    },
    {
      // title: 'Crypto financial trading apps for hedge fund',
      subtitle: "@Wee Twist, We try creating magic locally and we are capable of create that magic globally as well. We are committed to bring true artisan into the world of storytelling. We are not just a production company, but a creative hub with a constant itch to accept challenges if the idea is appealing. If you’re the one from overseas seeking beautiful, bright and vibrant Bengali landscapes with an eclectic mix of versatile cultures and people do not think twice before contacting us. We will ensure a smooth, hassle free production with all means. If you’re planning a Bangladesh-based production, our team will keep you as our top priority and assist you with everything. After all, we are a welcoming nation by culture and we treat our guests like gods.",
      // btnText: 'See Details',
      // btnLink: '/portfolio/portfolio-details',
      imageUrl: '/images/services/global-collaboration.png',
      category: 'International Collaborations',
    },
    {
      // title: 'Crypto financial trading apps for hedge fund',
      subtitle: "Documentaries have the power to shed light on important stories, provoke thought, and create awareness. We approach documentary films with a deep understanding of the subject matter, respecting the integrity of the narrative. Through our meticulous attention to detail, we capture the essence of your documentary and transform it into a visually immersive experience. By harmonizing visuals, music and sound design, we elevate the impact of your documentary, allowing audiences to connect with the subject matter on a deeper level.",
      // btnText: 'See Details',
      // btnLink: '/portfolio/portfolio-details',
      imageUrl: '/images/services/documentary.png',
      category: 'Documentary',
    },
  ];
  return (
    <>
      <PageHeading 
        title='What We Do'
        bgSrc='images/service_hero_bg.jpeg'
        pageLinkText='What We Do'
      />
      <Spacing lg='150' md='80'/>
      <Div className='cs-shape_wrap_4'>
        <Div className="cs-shape_4"></Div>
        <Div className="cs-shape_4"></Div>
        <Div className="container">
          <Div className="row">
            <Div className="col-xl-4">
              <SectionHeading
                title='Projects we can help you with' 
                subtitle='What We Do'
              />
              <Spacing lg='90' md='45'/>
            </Div>
            <Div className='col-xl-8'>
              <Div className='row'>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="ADVERTISEMENT"
                    link="/service/advertisement"
                    src="/images/what-we-do/advertisement.png"
                    alt="Service"
                    ServiceDetails=
                      "At our studio, we go beyond traditional advertising approaches and transform your brand message into captivating audio-visual experiences. Our team excels in creating advertising films that not only promote your product or service but also evoke emotions and engage your audience. By seamlessly blending music, storytelling, and stunning visuals, we craft advertisements that leave a lasting impression and drive impactful results."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="FEATURE FILMS"
                    link="/service/feature-films"
                    src="/images/what-we-do/feature-film.png"
                    alt="Service"
                    ServiceDetails="When it comes to feature films, we understand the power of visuals in conveying narratives and evoking deep emotions. Our expertise lies in translating the essence of your story into a visual masterpiece that complements the story and enhances the overall cinematic experience. Whether it's a romantic drama, a thrilling action flick, or a thought-provoking indie film, we have the creative prowess to bring your vision to life through our film production."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="WEB SERIES"
                    link="/service/web-series"
                    src="/images/what-we-do/web-series.png"
                    alt="Service"
                    ServiceDetails="In the era of digital content, web series have become immensely popular, capturing the attention of viewers worldwide. We recognize the significance of compelling visuals and well-crafted storytelling in the success of a web series. With our expertise in Web Series production, we create captivating episodes that seamlessly integrate music, visuals, and narratives. Our team ensures that each episode not only leaves the audience eagerly anticipating the next installment but also enhances the overall entertainment value of the web series."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="MUSIC VIDEOS"
                    link="/service/music-videos"
                    src="/images/what-we-do/music-videos.png"
                    alt="Service"
                    ServiceDetails="We don’t just make music videos, we create musical films by crafting an unique concept and visuals for your beloved musical project. We make sure our visuals add a layer of rhythm to your music."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                <Card
                    title="INTERNATIONAL COLLABORATIONS"
                    link="/service/international-collaborations"
                    src="/images/what-we-do/global-collaborations.png"
                    alt="Service"
                    ServiceDetails=
                      "@Wee Twist, We try creating magic locally and we are capable of create that magic globally as well. We are committed to bring true artisan into the world of storytelling. We are not just a production company, but a creative hub with a constant itch to accept challenges if the idea is appealing. If you’re the one from overseas seeking beautiful, bright and vibrant Bengali landscapes with an eclectic mix of versatile cultures and people do not think twice before contacting us. We will ensure a smooth, hassle free production with all means. If you’re planning a Bangladesh-based production, our team will keep you as our top priority and assist you with everything. After all, we are a welcoming nation by culture and we treat our guests like gods."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
                <Div className='col-lg-3 col-sm-6 cs-hidden_mobile'></Div>
                <Div className='col-lg-3 col-sm-6'>
                  <Card
                    title='DOCUMENTARY'
                    link='/service/documentary'
                    src='/images/what-we-do/documentary.png'
                    alt='Service'
                    ServiceDetails="Documentaries have the power to shed light on important stories, provoke thought, and create awareness. We approach documentary films with a deep understanding of the subject matter, respecting the integrity of the narrative. Through our meticulous attention to detail, we capture the essence of your documentary and transform it into a visually immersive experience. By harmonizing visuals, music and sound design, we elevate the impact of your documentary, allowing audiences to connect with the subject matter on a deeper level."
                  />
                  <Spacing lg='0' md='30'/>
                </Div>
              </Div>
            </Div>
          </Div>
        </Div>
      </Div>
      <Spacing lg='150' md='80'/>
            {/* Start Portfolio Section */}
            <Spacing lg="150" md="80" />
      {portfolioData.map((item, index) =>
        index % 2 === 0 ? (
          <Div key={index}>
            <Portfolio2
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
            <Spacing lg="100" md="70" />
          </Div>
        ) : (
          <Div key={index}>
            <Portfolio3
              title={item.title}
              subtitle={item.subtitle}
              btnText={item.btnText}
              btnLink={item.btnLink}
              imageUrl={item.imageUrl}
              category={item.category}
            />
            <Spacing lg="100" md="70" />
          </Div>
        ),
      )}
      <Spacing lg='150' md='80'/>
      {/* End Portfolio Section */}
      <Div className="container">
        <Cta 
          title='Let’s discuss make <br />something <i>cool</i> together' 
          btnText='Apply For Meeting' 
          btnLink='/contact' 
          bgSrc='/images/cta_bg.jpeg'
        />
      </Div>
    </>
  )
}

import React, { useState, useEffect } from "react";
import { Icon } from "@iconify/react";
import axiosInstance from "../../helper/axiosInterceptor";
import { pageTitle } from "../../helper";
import Cta from "../Cta";
import PageHeading from "../PageHeading";
import Portfolio from "../Portfolio";
import Div from "../Div";
import SectionHeading from "../SectionHeading";
import Spacing from "../Spacing";
import VideoModal from "../VideoModal";

export default function PortfolioPage() {
  pageTitle("Our Works");
  const [active, setActive] = useState("all");
  const [itemShow, setItemShow] = useState(5);

  const [portfolioPhotos, setPortFolioPhotos] = useState([]);
  const [portfolioVideos, setPortFolioVideos] = useState([]);

  useEffect(() => {
    axiosInstance("https://backend.weetwist.com//api/portfolio/photo", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(response.data[0]?.media_files);
      const data = response.data[0]?.media_files.map((item) => ({
        title: response.data[0]?.title,
        src: `https://backend.weetwist.com/${item.media_file}`,
      }));
      setPortFolioPhotos(data);
      console.log(portfolioPhotos);
    });
  }, []);

  useEffect(() => {
    axiosInstance("https://backend.weetwist.com//api/portfolio/video", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
      },
    }).then((response) => {
      console.log(response.data);
      const data = response.data?.map((item) => ({
        title: item.title,
        src: item.youtube_link,
      }));
      setPortFolioVideos(data);
      console.log(portfolioVideos);
    });
  }, []);

  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_4.jpeg',
  //     category: 'ui_ux_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_5.jpeg',
  //     category: 'logo_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_6.jpeg',
  //     category: 'web_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_7.jpeg',
  //     category: 'mobile_apps',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_8.jpeg',
  //     category: 'ui_ux_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_9.jpeg',
  //     category: 'web_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_10.jpeg',
  //     category: 'logo_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_4.jpeg',
  //     category: 'ui_ux_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_5.jpeg',
  //     category: 'logo_design',
  //   },
  //   {
  //     title: 'Colorful Art Work',
  //     subtitle: 'See Details',
  //     href: '/portfolio/portfolio-details',
  //     src: '/images/portfolio_6.jpeg',
  //     category: 'web_design',
  //   },
  // ];

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <PageHeading
        title="Our Works"
        bgSrc="images/portfolio_hero_bg.jpeg"
        pageLinkText="Our Works"
      />
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Div className="cs-portfolio_1_heading">
          <SectionHeading title="Some recent work" subtitle="Our Works" />
          {/* <Div className="cs-filter_menu cs-style1">
            <ul className="cs-mp0 cs-center">
              <li className={active === 'all' ? 'active' : ''}>
                <span onClick={() => setActive('all')}>All</span>
              </li>
              {categoryMenu.map((item, index) => (
                <li
                  className={active === item.category ? 'active' : ''}
                  key={index}
                >
                  <span onClick={() => setActive(item.category)}>
                    {item.title}
                  </span>
                </li>
              ))}
            </ul>
          </Div> */}
        </Div>
        <Div className="row">
          <Spacing lg="70" md="70" />
          {portfolioVideos.map((item, index) => (
            <Div key={index} className="col-lg-6">
              <VideoModal videoSrc={item?.src} bgUrl="/images/video_bg.jpeg" />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>
        <Spacing lg="90" md="45" />
        <Div className="row">
          {portfolioPhotos.slice(0, itemShow).map((item, index) => (
            <Div
              className={`${
                index === 3 || index === 6 ? "col-lg-8" : "col-lg-4"
              } ${
                active === "all"
                  ? ""
                  : !(active === item.category)
                  ? "d-none"
                  : ""
              }`}
              key={index}
            >
              <Portfolio
                title={item.title}
                subtitle={item.subtitle}
                href={item.href}
                src={item.src}
                variant="cs-style1 cs-type1"
              />
              <Spacing lg="25" md="25" />
            </Div>
          ))}
        </Div>

        <Div className="text-center">
          {portfolioPhotos.length <= itemShow ? (
            ""
          ) : (
            <>
              <Spacing lg="65" md="40" />
              <span
                className="cs-text_btn"
                onClick={() => setItemShow(itemShow + 3)}
              >
                <span>Load More</span>
                <Icon icon="bi:arrow-right" />
              </span>
            </>
          )}
        </Div>
      </Div>
      <Spacing lg="145" md="80" />
      <Div className="container">
        <Cta
          title="Let’s discuss & make <br />something <i>cool </i> together"
          btnText="Apply For Meeting"
          btnLink="/contact"
          bgSrc="/images/cta_bg.jpeg"
        />
      </Div>
    </>
  );
}
